<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
		<form ref="form" @submit.prevent="handleSubmit(saveUser)" autocomplete="off">
			<header class="modal-card-head">
				<h4 class="modal-card-title">
					<span>{{ $t(modalTitle(name)) }} <strong>{{ $t('user') }}</strong></span>
					<span v-if="userData.id">#{{ userData.id }}</span>
				</h4>
			</header>
			<div class="modal-card-body">
				<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
				<div class="modal-card mb-3">
					<div class="columns">
						<div class="column">
							<InputWithValidation name="name" rules="required|min:3" type="text" :label="$t('first_name')" size="is-medium" autofocus v-model="userData.first_name" />
						</div>
						<div class="column">
							<InputWithValidation rules="required|min:3" type="text" :label="$t('last_name')" size="is-medium" v-model="userData.last_name" />
						</div>
					</div>

					<InputWithValidation class="mb-4" rules="required|email" type="email" label="E-mail" size="is-medium" v-model="userData.email" />

					<SelectWithValidation v-if="user && user.role.name == 'master'" class="mb-4" rules="required" :label="$t('profile')" size="is-medium" v-model="userData.permission_id">
						<option v-for="r in permission" :value="r.id" :key="r.id">{{ r.name }}</option>
					</SelectWithValidation>

					<SelectWithValidation v-if="user && user.role.name == 'master' && userData.permission_id && [2, 3].includes(userData.permission_id)" class="mb-4" rules="required" :label="$t('company')" size="is-medium" v-model="userData.company_id">
						<option v-for="c in companies" :value="c.id" :key="c.id">{{ c.name }}</option>
					</SelectWithValidation>

					<InputWithValidation rules="required|min:8" type="password" :label="$t('password')" size="is-medium" password-reveal v-model="userData.password" v-if="name === 'New'" />
					<InputWithValidation type="password" :label="$t('password')" size="is-medium" password-reveal v-model="userData.password" v-if="name === 'Edit'" :disabled="root === 'root'" name="dssssdsdsdsdsds" />
					<password-meter class="mb-2" :password="userData.password" />

					<small class="modal-updated" v-if="userData.updated_at">{{ $t('last_change') }} {{ format(userData.updated_at) }}</small>
				</div>
			</div>
			<footer class="modal-card-foot">
				<b-button class="is-rounded is-outlined is-danger" @click="$emit('close')">{{ $t('close') }}</b-button>
				<b-button native-type="submit" class="is-rounded is-primary" :loading="loading">{{ $t('save') }}</b-button>
			</footer>
		</form>
	</ValidationObserver>
</template>

<script>
import InputWithValidation from '@/components/inputs/InputWithValidation'
import SelectWithValidation from '@/components/inputs/SelectWithValidation'
import { ValidationObserver } from 'vee-validate'
import PasswordMeter from 'vue-simple-password-meter'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { successToast, errorToast } from '@/mixins/toast'
import '@/mixins/generic'
import { mapState } from 'vuex'

export default {
	components: {
		InputWithValidation,
		SelectWithValidation,
		ValidationObserver,
		PasswordMeter
	},
	props: {
		id: {
			type: Number,
			required: false
		},
		name: {
			type: String,
			required: true
		},
		root: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			isOpening: false,
			loading: false,
			userData: {},
			role: 1,
			permission: [],
			visible: false,
			companies: []
		}
	},
	methods: {
		async getAllRoles() {
			try {
				const response = await Api.get('permission/findAll')
				const { status } = response
				if (status === 200) {
					const { data } = response
					this.permission = data
				}
			} catch (e) {
				console.log(e)
			}
		},
		async getAllCompanies() {
			try {
				const response = await Api.get('company/findSelect')
				const { status } = response
				if (status === 200) {
					const { data } = response
					this.companies = data
				}
			} catch (e) {
				console.log(e)
			}
		},
		async update() {
			try {
				this.loading = true
				const response = await Api.put(`user/update/${this.id}`, this.userData)
				const { status } = response
				if (status === 200) {
					// const { message } = response.data
					this.$emit('close')
					history.pushState({}, '', '/users')
					successToast(this.$t('user_updated_success'))
					eventHub.$emit('reload-users')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					// const { message } = e.data
					errorToast(this.$t('user_updated_error'))
				}
			} finally {
				this.loading = false
			}
		},
		async findById() {
			if (this.name === 'Edit') {
				this.isOpening = true
				try {
					const response = await Api.get(`user/findById/${this.id}`)
					const { status } = response
					if (status === 200) {
						const { data } = response
						this.userData = data
						this.isOpening = false
					}
				} catch (e) {
					console.log(e)
					if (e.data && e.data.message) {
						this.$emit('close')
						history.pushState({}, '', '/users')
						errorToast(e.data.message)
					}
				}
			}
		},
		async store() {
			try {
				this.loading = true
				const response = await Api.post('user/store', this.userData)
				const { status } = response
				if (status === 201 || status === 200) {
					// const { message } = response.data
					this.$emit('close')
					history.pushState({}, '', '/users')
					successToast(this.$t('user_created_success'))
					eventHub.$emit('reload-users')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					const { message } = e.data
					errorToast(this.$t('user_created_error') + '! <small>' + message + '</small>')
				}
			} finally {
				this.loading = false
			}

			this.loading = true
			setTimeout(() => {
				this.loading = false
			}, 1000)
		},
		async saveUser() {
			this.name === 'New' ? await this.store() : await this.update()
		}
	},
	computed: {
		...mapState('user', ['user'])
	},
	mounted() {
		this.findById()
		this.getAllRoles()
		this.getAllCompanies()
	}
}
</script>

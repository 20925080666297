import Vue from 'vue'

Vue.mixin({
	methods: {
		real: str => str.toLocaleString('pt-br', {
			style: 'currency',
			currency: 'BRL'
		}),
		capitalizeFirstLetter: str => str.charAt(0).toUpperCase() + str.slice(1),
		modalTitle: str => {
			let url = window.location.href.split('/')[3]
			return str == 'New' ? url == 'screens' ? 'Nova' : 'Novo' : 'Editar'
		},
		slugify: str => {
			str = str.replace(/^\s+|\s+$/g, '') // trim
			str = str.toLowerCase()

			// remove accents, swap ñ for n, etc
			var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
			var to = 'aaaaeeeeiiiioooouuuunc------'
			for (var i = 0, l = from.length; i < l; i++) {
				str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
			}

			str = str
				.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
				.replace(/\s+/g, '-') // collapse whitespace and replace by -
				.replace(/-+/g, '-') // collapse dashes
		},
		randomSize: (min = 20, max = 80) => {
			return (Math.random() * (max - min + 1)) + min
		},
		readFile (file) {
			return new Promise(resolve => {
				const reader = new FileReader()
				reader.addEventListener('load', () => resolve(reader.result), false)
				reader.readAsDataURL(file)
			})
		}
	}
})

<template>
	<Layout>
		<Header>
			<!-- <b-button v-if="isMobile" type="is-secondary filter" outlined @click="exportUsers($event)">
				<svg-icon class="icon is-small" icon="export"></svg-icon>
				<span>{{ $t('export') }}</span>
			</b-button> -->
			<b-button v-if="permissionEnabled('users', 'read')" type="is-secondary export" :loading="exporting" size="is-small" rounded outlined @click="exportUsers($event)">
				<svg-icon class="icon is-small" icon="export"></svg-icon>
				<span>{{ $t('export') }}</span>
			</b-button>
			<b-button v-if="permissionEnabled('users', 'create')" type="is-primary create" rounded @click="createUser($event)">
				<svg-icon icon="plus" class="icon is-small"></svg-icon>
				<span>{{ $t('add') }}</span>
			</b-button>
		</Header>
		<section v-if="!errored" class="filter">
			<div class="filter__wrapper">
				<b-field label="Ordem" v-model="filter.order" :label-position="label">
					<b-select :placeholder="$t('name')" @input="filterByOrder">
						<option selected value="1">{{ $t('name') }}</option>
						<option value="2">E-mail</option>
						<option value="4">Status</option>
					</b-select>
				</b-field>
				<b-field v-if="user && user.role.name == 'master'" :label="$t('profile')" v-model="role" :label-position="label">
					<b-select :placeholder="$t('all')" @input="filterByRole">
						<option v-for="r in permission" :value="r.id" :key="r.id">{{ r.name }}</option>
					</b-select>
				</b-field>
				<b-field label="Status" v-model="filter.status" :label-position="label">
					<b-select :placeholder="$t('active')" @input="filterByStatus">
						<option selected value="1">{{ $t('active') }}</option>
						<option value="2">{{ $t('inactive') }}</option>
					</b-select>
				</b-field>
				<b-field v-if="user" class="search mr-0">
					<b-input :placeholder="$t('search')" type="search" icon-right="magnify" v-model="first_name" @input="findByName"></b-input>
				</b-field>
				<div class="navigation" v-if="showPagination">
					<b-pagination @change="changeUrl" :current.sync="current" :total="total" :page="page" :per-page="pagination" :simple="true" :rounded="true" order="is-right" icon-prev="chevron-left" icon-next="chevron-right"></b-pagination>
				</div>
			</div>
		</section>
		<Error v-if="errored" :icon="true" :back="true" />
		<Results v-if="users.length == 0 && !loading" />
		<div v-if="loading" class="columns is-multiline">
			<div v-for="n in pagination" :key="n" class="column is-12-mobile is-6-tablet is-4-desktop">
				<Placeholder />
			</div>
		</div>
		<transition-group name="filtering" class="filtering columns is-multiline mb-5" tag="div">
			<div v-for="u in users" :key="u.id" class="column is-12-mobile is-6-tablet is-4-desktop">
				<article :class="[!u.active ? 'block inactive' : 'block']" @click.self="u.id != user.id ? updateUser(u.id) : ''">
					<b-checkbox class="block__checkbox" type="is-secondary" v-model="usersSelected" :native-value="u.id" :disabled="u.id == user.id"></b-checkbox>
					<div class="block__avatar image is-48x48" @click="u.id != user.id ? updateUser(u.id) : ''">
						<b-tooltip v-if="u.permission" :label="u.permission.name" type="is-primary" position="is-right">
							<span class="block__role" :style="{ background: u.permission.color }"></span>
						</b-tooltip>
						<b-image ratio="1by1" :src="u.avatar" :alt="u.full_name" :rounded="true"></b-image>
					</div>
					<div class="block__content" @click="u.id != user.id ? updateUser(u.id) : ''">
						<h3 class="block__name" translate="no">{{ u.full_name }}</h3>
						<p class="block__email is-ellipsis" translate="no">{{ u.email }}</p>
						<p class="block__email">{{ format(u.created_at) }} • {{ timeTo(u.created_at) }}</p>
					</div>
					<Trigger :id="u.id" v-if="u.id != user.id" :role="u.role ? u.role.name : ''" :name="u.full_name" :email="u.email" :active="u.active" :delete="u.deleted_at ? true : false" />
				</article>
			</div>
		</transition-group>
		<div class="page__selection">
			<b-button size="is-small" class="is-rounded is-outlined is-secondary" @click="selectAllUsers()">
				<span v-if="usersSelected.length >= 15">{{ $t('deselect_all') }}</span>
				<span v-else>{{ $t('select_all') }}</span>
			</b-button>
			<b-button size="is-small" class="is-rounded is-outlined is-danger ml-3" @click="deleteUsersSelected()" :disabled="usersSelected.length == 0">{{ $t('delete_selected') }}</b-button>
		</div>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
import Icon from '@/components/Icon'
import Placeholder from '@/components/placeholders/User'
import Trigger from '@/components/triggers/User'
import Error from '@/components/Error'
import Results from '@/components/Results'
import Modal from '@/components/modals/User'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { create, update } from '@/mixins/modal'
import { successToast, errorToast } from '@/mixins/toast'
import { mapGetters } from 'vuex'
import { mapState } from 'vuex'
import actionUrl from '@/mixins/actionurl'
// import { responsive } from '@/mixins/responsive'

export default {
	components: {
		Layout,
		Header,
		Placeholder,
		Trigger,
		Error,
		Results,
		'svg-icon': Icon
	},
	mixins: [
		// responsive,
		actionUrl
	],
	data() {
		return {
			// Pagination
			current: 1,
			page: 1,
			total: 0,
			pagination: 15,
			data: [],
			loading: true,
			errored: false,
			// Filter
			filter: {
				order: '',
				status: '1',
				role: ''
			},
			order: '',
			role: '',
			status: 1,
			label: 'on-border',
			exporting: false,
			actions: [
				{
					name: 'Editar',
					icon: 'edit'
				},
				{
					name: 'Deletar',
					icon: 'trash',
					color: 'has-text-danger'
				}
			],
			permission: [],
			first_name: '',
			showPagination: false,
			destroy: false,
			usersSelected: []
		}
	},
	async created() {
		const url = window.location.href.split('/')
		const type = url.find(el => el === 'page')
		if (type === 'page') {
			this.page = url[5]
			this.current = url[5]
		}
	},
	mounted() {
		this.getAllRoles()
		this.getAllUsers()
		this.getUrlParams()
		//eventHub.$off()
		eventHub.$on('edit-modal-users', obj => {
			update(this, 'users', obj.id, Modal, 'Edit', obj.root)
		})
		eventHub.$on('disable-users', async obj => {
			try {
				const response = await Api.put(`user/disable/${obj.id}`)
				const { status } = response
				if (status === 200) {
					// const { message } = response.data
					successToast(this.$t('user_disabled_success'))
					await this.getAllUsers()
				}
			} catch (e) {
				console.log(e)
			}
		})
		eventHub.$on('delete-users', obj => {
			this.$buefy.dialog.alert({
				size: 'is-delete',
				type: 'is-outlined is-primary',
				title: this.$t('attention'),
				message: '<span>' + this.$t('alert_delete_user') + '</span>',
				canCancel: true,
				focusOn: 'cancel',
				cancelText: this.$t('no'),
				confirmText: this.$t('yes'),
				onConfirm: async () => {
					try {
						const response = await Api.delete(`user/destroy/${obj.id}`)
						const { status } = response
						if (status === 200) {
							// const { message } = response.data
							successToast(this.$t('user_removed_success'))
							await this.getAllUsers()
						}
					} catch (e) {
						console.log(e)
					}
				}
			})
		})
		eventHub.$on('restore-users', async obj => {
			try {
				const response = await Api.put(`user/restore/${obj.id}`)
				const { status } = response
				if (status === 200) {
					// const { message } = response.data
					successToast(this.$t('user_enabled_success'))
					await this.getAllUsers()
				}
			} catch (e) {
				console.log(e)
			}
		})
		eventHub.$on('reload-users', () => {
			this.getAllUsers()
		})
	},
	destroyed() {
		eventHub.$off('edit-modal-users')
		eventHub.$off('disable-users')
		eventHub.$off('delete-users')
		eventHub.$off('restore-users')
		eventHub.$off('reload-users')
	},
	computed: {
		users() {
			const url = window.location.href.split('/')
			const type = url.find(el => el === 'page')
			if (type === 'page') {
				let current = this.current - 1
				return this.data.slice(current * this.pagination, (current + 1) * this.pagination)
			} else {
				let current = this.current - 1
				return this.data.slice(current * this.pagination, (current + 1) * this.pagination)
			}
		},
		...mapGetters('user', ['permissionEnabled']),
		...mapState('user', ['user']),

	},
	methods: {
		async getAllRoles() {
			try {
				const response = await Api.get('permission/findAll')
				const { status } = response
				if (status === 200) {
					const { data } = response
					this.permission = data
					this.permission.route = data.route
				}
			} catch (e) {
				console.log(e)
			}
		},
		async getAllUsers() {
			try {
				const response = await Api.get('user/findAll?status=' + (this.filter.status ? this.filter.status : '1'))
				const { status } = response
				if (status === 200) {
					const { data } = response
					this.data = data
					// console.log(data)
					this.total = data.length
					this.showPagination = this.total > 15 ? true : false
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		async findByName() {
			try {
				const empty = /^\s*$/
				if (!empty.test(this.first_name)) {
					const response = await Api.post('user/findByName', {
						name: this.first_name,
						status: this.filter.status ? this.filter.status : null
					})
					const { status } = response
					if (status === 200) {
						const { data } = response
						this.data = data
						this.total = data.length
						// console.log(data)
					}
				} else {
					await this.getAllUsers()
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		async filterByOrder(e) {
			try {
				this.filter.order = e
				const response = await Api.post('user/filterByOrder', {
					name: this.filter.order,
					status: this.filter.status ? this.filter.status : null
				})
				const { status } = response
				if (status === 200) {
					const { data } = response
					this.data = data
					this.total = data.length
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		async filterByStatus(e) {
			try {
				this.filter.status = e
				// console.log(e)
				const response = await Api.post('user/filterByStatus', {
					name: this.filter.status,
					active: e
				})
				const { status } = response
				// console.log(response)
				if (status === 200) {
					const { data } = response
					this.data = data
					this.total = data.length
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		async filterByRole(e) {
			try {
				this.filter.role = e
				const response = await Api.post('user/filterByRole', {
					name: this.filter.role,
					status: this.filter.status ? this.filter.status : null
				})
				const { status } = response
				if (status === 200) {
					const { data } = response
					this.data = data
					this.total = data.length
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		async exportUsers() {
			this.exporting = true
			try {
				const response = await Api.post('user/export')
				const { status } = response
				if (status === 422) {
					errorToast(this.$t('user_exported_error'))
				} else {
					const { message } = response.data
					successToast(this.$t('user_exported_success'))
					setTimeout(() => {
						this.exporting = false
						const node = document.createElement('a')
						node.href = message
						node.click()
					}, 2000)
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.exporting = false
			}
		},
		getUrlParams() {
			const url = location.href.split('/')
			const params = url[4]
			const type = url.find(el => el === params)
			switch (type) {
				case 'create':
					this.createUser()
					break
				case 'edit':
					this.updateUser(parseInt(url[5]))
			}
		},
		createUser() {
			if (this.permissionEnabled('users', 'create')) {
				create(this, 'users', Modal, 'New')
			}
		},
		updateUser(id) {
			if (this.permissionEnabled('users', 'edit')) {
				update(this, 'users', id, Modal, 'Edit')
			}
		},
		changeUrl(page) {
			this.current = page
			if (page === 1) {
				history.pushState({}, '', '/users')
			} else {
				history.pushState({}, '', '/users')
				history.pushState({}, '', `users/page/${this.current}`)
			}
		},
		selectAllUsers() {
			if (this.usersSelected.length == (this.data.length - 1)) {
				this.usersSelected = []
			} else {
				let users = this.data.filter(d => d.id != this.user.id)
				this.usersSelected = users.map((d) => {
					return d.id
				})
			}
		},
		deleteUsersSelected() {
			this.$buefy.dialog.alert({
				size: 'is-delete',
				type: 'is-outlined is-primary',
				title: this.$t('attention'),
				message: '<span>' + this.$t('alert_delete_multiple_user') + '</span>',
				canCancel: true,
				focusOn: 'cancel',
				cancelText: this.$t('no'),
				confirmText: this.$t('yes'),
				onConfirm: async () => {
					Api.post('user/deleteMultiple', { ids: this.usersSelected })
						.then(() => {
							this.data = []
							this.usersSelected = []
							this.loading = true
							this.getAllUsers()
						})
						.catch(() => {
							errorToast(this.$t('user_delete_multiple_error'))
						})
				}
			})
		}
	}
}
</script>
